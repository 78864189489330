
































/**
 * ToDo
 * 1. 옵션 이미지 있을 경우 토글 오픈시 해당 컨텐츠 최상단으로...
 * 2. 옵션 이미지, 설명 data 있을시 확인
 */
import { Vue, Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DataFormat from '@/shared/utils/dataFormat';

@Component
export default class ProductConfig extends mixins(DataFormat) {
  @Prop({
    required: true
  })
  public product!: any;
  @Prop()
  public selectLang!: any;

  public open(e) {
    const re = e.currentTarget;
    const scroll = re.offsetTop;
    if (!re.classList.contains('more-none')) {
      if (re.classList.contains('open')) {
        re.classList.remove('open');
      } else {
        re.classList.add('open');
        window.scrollTo(0, scroll - 80);
      }
    } else {
      return false;
    }
  }
}
